import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { csrfFetch } from '../../../store/csrf.js';
import * as sessionActions from "../../../store/session";

const CreateBookdraft = ({ user, setShowBookdraftForm }) => {
    const [author_name, setAuthorName] = useState("");
    const [title, setTitle] = useState("");
    const [is_premium, setIsPremium] = useState(false);
    const [ref_link, setRefLink] = useState("");
    const [temperature, setTemperature] = useState(.8);
    const [bookdraftExistsMessage, setBookdraftExistsMessage] = useState("");

    //const sessionUser = useSelector(state => state.session.user);
//
    //useEffect(() => {
    //  async function checkUser() {
    //    if (sessionUser.user_id !== 14) {
    //      console.log('hello')
    //      window.location.redirect('/')
    //    }
    //  }
//
    //  checkUser();
    //}, [sessionUser])

    const checkIfBookExists = async(e) => {
      e.preventDefault();
      const response = await csrfFetch("api/books/check-if-bookdraft-exists", {
        method: "POST",
        body: JSON.stringify({
          book_title: title
        })
      })
      const data = await response.json();
      if (data.books) {
        let existingBooksArray = [];
        data.books.forEach(book => {
          let bookRecord = {
            title: book.title, 
            author_id: book.author_id
          }
          existingBooksArray.push(bookRecord)
        })
        setBookdraftExistsMessage(JSON.stringify(existingBooksArray))
      } else {
        setBookdraftExistsMessage(data.message);
      }
    }
    const submitBookdraft = async(e) => {
        e.preventDefault();
        const response = await csrfFetch("/api/captain/create-bookdraft", {
            method: "POST",
            body: JSON.stringify({
                author_name: author_name, 
                title: title, 
                is_premium: is_premium, 
                ref_link: ref_link, 
                temperature: temperature,
                user_id: user.user_id
            })
          });
          const data = await response.json();
    
          return data
    }

    const hideBookdraftForm = () => {
        setShowBookdraftForm(false);
    }
    
    return(
        <div className="flex flex-col items-center justify-center mt-10">
            <form className="flex flex-col text-xl items-center justify-center mt-10 border-2 border-lightningblue text-white" onSubmit={submitBookdraft}>
                <label className="py-2">
                  Author Name
                </label>
                <input 
                    className="border-2 border-black w-[300px] h-[36px] text-black"
                    type="text"
                    value={author_name}
                    onChange={(e) => setAuthorName(e.target.value)}
                    required
                  />
                <label className="py-2">
                  Title
                </label>
                <input 
                    className="border-2 border-black w-[300px] h-[36px] text-black"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                <label className="py-2">
                  Is Premium?
                </label>
                <input 
                    className="border-2 border-black w-[300px] h-[36px] text-black"
                    type="text"
                    value={is_premium}
                    onChange={(e) => setIsPremium(e.target.value)}
                    required
                  />
                <label className="py-2">
                  Ref Link
                </label>
                <input
                    className="border-2 border-black w-[300px] h-[36px] text-black"
                    type="text"
                    value={ref_link}
                    onChange={(e) => setRefLink(e.target.value)}
                    required
                  />
                <label className="py-2">
                  Temperature: 0 - 1
                </label>
                <input
                    className="border-2 border-black w-[300px] h-[36px] text-black"
                    type="number"
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                    required
                  />
                <button type="submit">Submit</button>
                <button onClick={checkIfBookExists}>Check If Book Exists</button>
                <p>{bookdraftExistsMessage}</p>
                <button onClick={hideBookdraftForm} >Close</button>
            </form>

    
        </div>
    )
}

export default CreateBookdraft;