import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as DownArrow } from './../../Assets/Images/down-arrow.svg';
import { ReactComponent as UpArrow } from './../../Assets/Images/up-arrow.svg';
import { ReactComponent as Downbars } from './../../Assets/Images/down-bars-white.svg';
import { ReactComponent as SearchGlass } from './../../Assets/Images/search-glass.svg';

function AlphabatizedBooks({ alphaBooks, authors }) {
    const [expandedBookIndex, setExpandedBookIndex] = useState(null); // Track expanded book by index
    const [booksToDisplay, setBooksToDisplay] = useState(5);
    const [showDownBar, setShowDownBar] = useState(true);
    const [alphaSymbols, setAlphaSymbols] = useState(['a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'number',
    'special'
    ])
    const [searchTitle, setSearchTitle] = useState("");
    const [alphaSymbol, setAlphaSymbol] = useState("");
    const sessionBooks = useSelector(state => state.session.books);
    const sessionAuthors = useSelector(state => state.session.authors);
    const [books, setBooks] = useState([]);
    const [searchBooks, setSearchBooks] = useState([]);
    const [showSearchBooks, setShowSearchBooks] = useState(false);
    const [showBooks, setShowBooks] = useState(false)

    const setBookSymbol = (symbol) => {
      setAlphaSymbol(symbol)
      setBooks(alphaBooks["alphabetizedBooks"][symbol])
      setShowBooks(true)
    }

        // Function to handle expand/collapse click
        const handleExpandClick = (index) => {
          // Toggle the expanded state for the clicked book
          if (expandedBookIndex === index) {
              setExpandedBookIndex(null); // Collapse if it's already expanded
          } else {
              setExpandedBookIndex(index); // Expand the clicked book
          }
      };

      const returnToListing = () => {
        setShowBooks(false);
        setShowSearchBooks(false);
      }

      const searchForTitles = () => {
        const escapedSearchTerm = searchTitle.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  
        // Create a RegExp object for partial match, 'i' for case-insensitive
        const pattern = new RegExp(escapedSearchTerm, 'i');

        let booksFiltered = sessionBooks.filter(book =>
          book.title.split(' ').some(word => pattern.test(word)) || // Any word in the title matches
          pattern.test(book.title) // The title somewhat matches the search term
        );
        setSearchBooks(booksFiltered);
        setShowSearchBooks(true);
      }
      
    //text should be static but on expand increase div height and allow scroll for text
    return (
      <div>
          {!showBooks && !showSearchBooks &&
          <div className="font-scrivano">
              <label className="px-2 text-white">Search By Title</label>
              <div className="flex flex-row px-2 mb-4">
                <input
                  className="w-[84%]"
                  type="text"
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
                <SearchGlass onClick={searchForTitles} className="h-[30px] w-[16%]" />
              </div>
              <p className="mt-4 text-white text-center tracking-[.5px]">-OR-</p>
              <p className="mb-4 text-white text-center tracking-[.5px]">Filter Alphabetically</p>
              <div className='px-2 overflow-y-auto h-[650px] grid grid-cols-4 items-center'>
                    {alphaSymbols.map((symbol, index) => {
                        return (
                        <div key={index} className="h-[70px] w-[80px] rounded-md border-2 border-lightningblue self-center content-center text-center"> 
                            <p onClick={() => setBookSymbol(symbol)} className="text-white owners-text tracking-[.5px] self-center content-center text-xl text-white">{symbol}</p>
                        </div>
                        )
                    })}
              </div>
            </div>
          }
        
        {showBooks && !showSearchBooks && <p onClick={returnToListing} className="p-4 font-scrivano text-lightningblue">&#8592; Back</p>}
        {showBooks && !showSearchBooks &&
        books?.slice(0, booksToDisplay).map((book, index) => {
        // Find the matching author by author_id
        const author = authors?.find(author => author.author_id === book.author_id);
        // Use author_name if found, otherwise use a placeholder like "Unknown Author"
        const authorName = author ? author.author_name : 'Unknown Author';

        return (
            <div key={index} className='flex flex-col items-center justify-center'>
                <div className="opacity-25 border-[.5px] border-white w-[96%] mt-6 mb-6"></div>
                <h1 className="text-lightningblue text-center font-scrivano italic text-xl">{book.title}</h1>
                <p className="leading-6 py-2 whitespace-pre-line px-8 owners-text text-start tracking-[.5px] text-white text-xs animate-grow">
                    {expandedBookIndex === index ? book.summary : book.summary.slice(0, 150)}
                </p>
                {/* Display the author's name instead of the author_id */}
                <p className="py-1 px-12 font-scrivano self-end opacity-100 text-xs text-lightningblue">{authorName}</p>
                <div className="flex mt-2 cursor-pointer font-scrivano" onClick={() => handleExpandClick(index)}>
                    <p className="opacity-100 text-xs text-lightningblue">
                        {expandedBookIndex === index ? 'Collapse' : 'Expand'}
                    </p>
                    {/* Conditional rendering for the DownArrow icon can be implemented here if needed */}
                    {expandedBookIndex === index ? <UpArrow className="pt-1 ml-2 h-[15px]" /> : <DownArrow className="pt-1 ml-2 h-[15px]" />}
                </div>
            </div>
        );
    })}

    {!showBooks && showSearchBooks && <p onClick={returnToListing} className="p-4 text-lightningblue">&#8592; Back</p>}
    {!showBooks && showSearchBooks &&
        searchBooks?.slice(0, booksToDisplay).map((book, index) => {
        // Find the matching author by author_id
        const author = authors?.find(author => author.author_id === book.author_id);
        // Use author_name if found, otherwise use a placeholder like "Unknown Author"
        const authorName = author ? author.author_name : 'Unknown Author';

        return (
            <div key={index} className='flex flex-col items-center justify-center'>
                <div className="opacity-25 border-[.5px] border-white w-[96%] mt-6 mb-6"></div>
                <h1 className="text-lightningblue text-center italic text-xl">{book.title}</h1>
                <p className="leading-6 py-2 whitespace-pre-line px-8 owners-text text-start tracking-[.5px] text-white text-xs animate-grow">
                    {expandedBookIndex === index ? book.summary : book.summary.slice(0, 150)}
                </p>
                {/* Display the author's name instead of the author_id */}
                <p className="py-1 px-12 self-end opacity-100 text-xs text-lightningblue">{authorName}</p>
                <div className="flex mt-2 cursor-pointer" onClick={() => handleExpandClick(index)}>
                    <p className="opacity-100 text-xs text-lightningblue">
                        {expandedBookIndex === index ? 'Collapse' : 'Expand'}
                    </p>
                    {/* Conditional rendering for the DownArrow icon can be implemented here if needed */}
                    {expandedBookIndex === index ? <UpArrow className="pt-1 ml-2 h-[15px]" /> : <DownArrow className="pt-1 ml-2 h-[15px]" />}
                </div>
            </div>
        );
    })}
      </div>
    );
}

export default AlphabatizedBooks;