import { React, useState } from 'react';
import { useSelector } from "react-redux";
import SignupCTA from '../../../LoginSignup/SignupCTA';

function BookMenu({ menuSelection, setMenuSelection }) {
    const sessionUser = useSelector(state => state.session.user);
    const [showSignUpCTA, setShowSignupCTA] = useState(false);

    const isSelected = (item) => {
        if (item === menuSelection) {
            return true;
        } else {
            return false;
        }
    };

    const showSignup = () => {
        setShowSignupCTA(true);
    }

    return (
        <div className="flex justify-evenly items-center font-scrivano text-lightningblue my-4 h-20">
            {showSignUpCTA &&
            <SignupCTA setShowSignupCTA={setShowSignupCTA} />
            }
            <div onClick={() => setMenuSelection("Latest Books")} className={isSelected("Latest Books") ? "border-[1px] p-3 rounded-md border-lightningblue hover:bg-white" : "p-3 hover:bg-white"}>
                <p>Latest Books</p>
            </div>
            <div onClick={() => setMenuSelection("Search")} className={isSelected("Search") ? "border-[1px] p-3 rounded-md border-lightningblue hover:bg-white" : "p-3 hover:bg-white"}>
                <p>Alpha</p>
            </div>
            {sessionUser &&
            <div onClick={() => setMenuSelection("Bookmarks")} className={isSelected("Bookmarks") ? "border-[1px] p-3 rounded-md border-lightningblue hover:bg-white" : "p-3 hover:bg-white"}>
                <p>Bookmarks</p>
            </div>
            }
            {!sessionUser &&
            <div onClick={showSignup} className={isSelected("Bookmarks") ? "border-[1px] p-3 rounded-md border-lightningblue hover:bg-white" : "p-3 hover:bg-white"}>
                <p>Bookmarks</p>
            </div>
            }
            {sessionUser && 
            <div onClick={() => setMenuSelection("Profile")} className={isSelected("Profile") ? "border-[1px] p-3 rounded-md border-lightningblue hover:bg-white" : "p-3 hover:bg-white"}>
                <p>Profile</p>
            </div>
            }
            {!sessionUser && 
            <div onClick={() => setMenuSelection("Login")} className={isSelected("Login") ? "border-[1px] p-3 rounded-md border-lightningblue hover:bg-white" : "p-3 hover:bg-white"}>
                <p>Login</p>
            </div>
            }
        </div>
    )
}

export default BookMenu;