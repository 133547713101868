import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownArrow } from './../../Assets/Images/down-arrow.svg';
import { ReactComponent as UpArrow } from './../../Assets/Images/up-arrow.svg';
import { ReactComponent as Downbars } from './../../Assets/Images/down-bars-white.svg';
import { ReactComponent as EmptyBookmark } from './../../Assets/Images/bookmark.svg';
import { ReactComponent as FilledBookmark } from './../../Assets/Images/filled-blue-bookmark.svg';
import SignupCTA from '../../LoginSignup/SignupCTA';
import * as sessionActions from "../../../store/session";

function Bookmarks({ booksToShow, authors }) {
    const dispatch = useDispatch();
    const [expandedBookIndex, setExpandedBookIndex] = useState(null); // Track expanded book by index
    const [booksToDisplay, setBooksToDisplay] = useState(5);
    const [showDownBar, setShowDownBar] = useState(true);
    const [userBookmarks, setUserBookmarks] = useState([]);
    const [showSignupCTA, setShowSignupCTA] = useState(false);
    const sessionAuthors = useSelector(state => state.session.authors);
    const sessionBookmarks = useSelector(state => state.session.bookmarks);
    const sessionUser = useSelector(state => state.session.user);
    const [books, setBooks] = useState([]);

    useEffect(() => {
        let bookmarkIds = [];
        sessionBookmarks?.bookmarks.forEach(mark => {
            bookmarkIds.push(mark.book_id);
        })

        let booksForUser = []
        setUserBookmarks(bookmarkIds)
        booksToShow?.forEach(book => {
            if (bookmarkIds.indexOf(book.book_id) !== -1) {
                booksForUser.push(book);
            }
        })

        setBooks(booksForUser);
        //console.log(bookmarkIds)
        //console.log(booksToShow)
    },[sessionBookmarks])


    // Function to handle expand/collapse click
    const handleExpandClick = (index) => {
        // Toggle the expanded state for the clicked book
        if (expandedBookIndex === index) {
            setExpandedBookIndex(null); // Collapse if it's already expanded
        } else {
            setExpandedBookIndex(index); // Expand the clicked book
        }
    };


    const triggerSignup = () => {
        console.log("No user ID")
        setShowSignupCTA(true);
    }

    const saveBookmark = (user_id, book_id) => {
        if (!user_id) {
            triggerSignup();
        } else {
            dispatch(sessionActions.saveBookmark({ user_id, book_id }))
        }
    }

    const deleteBookmark = (user_id, book_id) => {
        dispatch(sessionActions.deleteBookmark({ user_id, book_id }))
    }
    
    return (
        <div className='overflow-y-auto h-[650px]'>
            {books && books.length > 0 && books?.slice(0, booksToDisplay).map((book, index) => {
                // Find the matching author by author_id
                const author = authors?.find(author => author.author_id === book.author_id);
                // Use author_name if found, otherwise use a placeholder like "Unknown Author"
                const authorName = author ? author.author_name : 'Unknown Author';
        
                return (
                    <div key={index} className='relative flex flex-col items-center justify-center'>
                        <div className="opacity-25 border-[.5px] border-white w-[96%] mt-6 mb-6"></div>
                        <div className="flex">
                            {book?.title.length <= 32 ? <h1 className="text-lightningblue text-center font-scrivano italic text-xl">{book.title} </h1> : <h1 className="text-lightningblue text-center italic text-xl">{book.title.slice(0, 32)}...</h1>}
                            {<FilledBookmark className="w-[20px] ml-4" onClick={() => deleteBookmark(sessionUser?.user_id, book.book_id)} />}
                        </div>
                        <p className="leading-6 py-2 whitespace-pre-line px-8 owners-text text-start tracking-[.5px] text-white text-xs animate-grow">
                            {expandedBookIndex === index ? book.summary : book.summary.slice(0, 150)}
                        </p>
                        <p className="py-1 px-12 self-end opacity-100 font-scrivano text-xs text-lightningblue">{authorName}</p>
                        <div className="flex mt-2 cursor-pointer font-scrivano" onClick={() => handleExpandClick(index)}>
                            <p className="opacity-100 text-xs text-lightningblue">
                                {expandedBookIndex === index ? 'Collapse' : 'Expand'}
                            </p>
                            {/* Conditional rendering for the DownArrow icon can be implemented here if needed */}
                            {expandedBookIndex === index ? <UpArrow className="pt-1 ml-2 h-[15px]" /> : <DownArrow className="pt-1 ml-2 h-[15px]" />}
                        </div>
                    </div>
                );
            })}
            {books && books.length === 0 && 
            <div className="flex flex-col justify-center font-scrivano">
                <p className="text-center text-white px-10">You don't have any bookmarks.</p>
                <p className="text-center text-white px-10 mt-2">Bookmark a book to view it here.</p>
            </div>
            }
        </div>
    )    
}

export default Bookmarks;