import { csrfFetch } from './csrf';

const SET_USER = 'session/setUser';
const SET_BOOKS = 'session/setBooks';
const SET_ALPHA_BOOKS = 'session/setAlphaBooks';
const SET_AUTHORS = 'session/setAuthors';
const SET_BOOKMARKS = 'session/setBookmarks'
const REMOVE_USER = 'session/removeUser';
const REMOVE_BOOKS = 'session/removeBooks';
const REMOVE_ALPHA_BOOKS = 'session/removeAlphaBooks';
const REMOVE_AUTHORS = 'session/removeAuthors';
const REMOVE_BOOKMARKS = 'session/removeBookmarks';

const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

const setBooks = (books) => {
  return {
    type: SET_BOOKS,
    payload: books,
  };
};

const setAlphaBooks = (alphaBooks) => {
  return {
    type: SET_ALPHA_BOOKS,
    payload: alphaBooks,
  };
};

const setBookmarks = (bookmarks) => {
  return {
    type: SET_BOOKMARKS,
    payload: bookmarks,
  }
}

const setAuthors = (authors) => {
  return {
    type: SET_AUTHORS,
    payload: authors,
  };
};

const removeBooks = () => {
  return {
    type: REMOVE_BOOKS,
  };
};

const removeAlphaBooks = () => {
  return {
    type: REMOVE_ALPHA_BOOKS,
  };
};

const removeBookmarks = () => {
  return {
    type: REMOVE_BOOKMARKS,
  }
}

const removeAuthors = () => {
  return {
    type: REMOVE_AUTHORS,
  };
};

export const login = (user) => async (dispatch) => {
    const { credential, password } = user;
    const response = await csrfFetch('/api/session', {
      method: 'POST',
      body: JSON.stringify({
        credential,
        password,
      }),
    });
    const data = await response.json();
    dispatch(setUser(data.user));
    return response;
  };

  export const restoreUser = () => async dispatch => {
    const response = await csrfFetch('/api/session');
    const data = await response.json();
    dispatch(setUser(data.user));
    return response;
  };

  export const signup = (user) => async (dispatch) => {
    const { first_name, last_name, username, email, password } = user;
    const response = await csrfFetch("/api/users", {
      method: "POST",
      body: JSON.stringify({
        first_name,
        last_name,
        username,
        email,
        password,
      }),
    });
    const data = await response.json();
    dispatch(setUser(data.user));
    return response;
  };

  export const logout = () => async (dispatch) => {
    const response = await csrfFetch('/api/session', {
      method: 'DELETE',
    });
    dispatch(removeUser());
    return response;
  };

  export const getBooks = () => async (dispatch) => {
    const response = await csrfFetch('/api/books/get-books', {
      method: 'GET',
    });
    const data = await response.json();
    dispatch(setBooks(data.books));
    return response;
  };

  export const getAuthors = () => async (dispatch) => {
    const response = await csrfFetch('/api/books/get-authors', {
      method: 'GET',
    });
    const data = await response.json();
    dispatch(setAuthors(data.authors));
    return response;
  };

  export const getAlphaBooks = () => async (dispatch) => {
    const response = await csrfFetch('/api/books/get-alpha-books', {
      method: 'GET',
    });
    const data = await response.json();
    dispatch(setAlphaBooks(data));
    return response;
  };

  export const getBookmarks = (userId) => async (dispatch) => {
    if (!userId) {
      return 
    } else {
    const response = await csrfFetch(`/api/books/get-bookmarks/${userId}`, {
      method: 'GET',
    });
    const data = await response.json();
    dispatch(setBookmarks(data));
    return response;
    }
  };

  export const saveBookmark = (bookmark) => async (dispatch) => {
    const { user_id, book_id } = bookmark;
    const response = await csrfFetch("/api/books/create-bookmark", {
      method: "POST",
      body: JSON.stringify({
        user_id,
        book_id
      }),
    });
    const data = await response.json();
    dispatch(getBookmarks(user_id))
    return response;
  };

  export const deleteBookmark = (bookmark) => async (dispatch) => {
    const { user_id, book_id } = bookmark;
    const response = await csrfFetch("/api/books/delete-bookmark", {
      method: "POST",
      body: JSON.stringify({
        user_id,
        book_id
      }),
    });
    const data = await response.json();
    dispatch(getBookmarks(user_id))
    return response;
  };

const initialState = { user: null };

const sessionReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_USER:
      newState = Object.assign({}, state);
      newState.user = action.payload;
      return newState;
    case REMOVE_USER:
      newState = Object.assign({}, state);
      newState.user = null;
      return newState;
    case SET_BOOKS:
      newState = Object.assign({}, state);
      newState.books = action.payload;
      return newState;
    case REMOVE_BOOKS:
      newState = Object.assign({}, state);
      newState.books = null;
      return newState;
    case SET_AUTHORS:
      newState = Object.assign({}, state);
      newState.authors = action.payload;
      return newState;
    case REMOVE_AUTHORS:
      newState = Object.assign({}, state);
      newState.authors = null;
      return newState;
    case SET_ALPHA_BOOKS:
      newState = Object.assign({}, state);
      newState.alphaBooks = action.payload;
      return newState;
    case REMOVE_ALPHA_BOOKS:
      newState = Object.assign({}, state);
      newState.alphaBooks = null;
      return newState;
    case SET_BOOKMARKS:
      newState = Object.assign({}, state);
      newState.bookmarks = action.payload;
      return newState;
    case REMOVE_BOOKMARKS:
      newState = Object.assign({}, state);
      newState.bookmarks = null;
      return newState;
    default:
      return state;
  }
};

export default sessionReducer;