import { React, useState } from 'react';
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';

function LoginSignup() {
    const [showLogin, setShowLogin] = useState(true);

    return (
        <div className="w-full">
            {showLogin ? <LoginModal setShowLogin={setShowLogin} /> : <SignupModal setShowLogin={setShowLogin} />}
        </div>
    )
}

export default LoginSignup;