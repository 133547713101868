import { React, useState } from 'react';
import lightningchains from './../Assets/Images/lightningchain.png'
import naveImg from '../Assets/Images/nav-img.png';

function Navigation() {
    return (
        <div className="-mt-6 bg-lightningblue bg-opacity-0 max-w-[600px]">
            <img className="" src={naveImg}></img>
        </div>
    )
}

export default Navigation;