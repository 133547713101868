import React, { useState } from 'react';
import * as sessionActions from '../../../store/session';
import { useDispatch, useSelector } from 'react-redux';

function Profile() {
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);

    const logout = () => {
        dispatch(sessionActions.logout()).then(() => {window.location.reload()})
    }
    return (
        <div>
            <div className="flex flex-col justify-center items-center">
                <p className="py-1 text-xl text-white">Email:</p>
                <p className="py-1 text-xl text-white">{sessionUser?.email}</p>
                <p className="py-1 text-xl text-white">Username:</p>
                <p className="py-1 text-xl text-white">{sessionUser?.username}</p>
                <button onClick={logout} className="h-10 mt-6 border-white border-[.1px] text-white align-center rounded-md w-[280px] bg-diagonal-gradient from-lightningblue to-darkpurple">Logout</button>
            </div>
        </div>
    )
}

export default Profile;