import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as sessionActions from "../../../store/session";
import TurnstileWidget from '../../TurnstileWidget';

function SignupCTA({ setShowSignupCTA }) {
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [isVerified, setIsVerified] = useState(false);

  //if (sessionUser) return <Redirect to="/" />;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setErrors([]);
      return dispatch(sessionActions.signup({ first_name, last_name, email, username, password })).then(setShowSignupCTA(false))
        .catch(async (res) => {
          const data = await res.json();
          if (data && data.errors) setErrors(data.errors);
        });
    }
    return setErrors(['Confirm Password field must be the same as the Password field']);
  };

  const closeSignUpCTA = () => {
    setShowSignupCTA(false);
  }

  return (
    <div className="font-scrivano">
        <form onSubmit={handleSubmit} className="w-screen px-4 h-[600px] fixed top-10 left-0 right-0 bottom-0 w-screen flex flex-col justify-center items-center z-[51]">
            <p onClick={closeSignUpCTA} className="mb-8 pl-2 self-start text-3xl text-white">x</p>
            <p className="text-white text-center mb-8">Create An Account To Add Bookmarks. Don't be a scallywag, it's Free!</p>
            <ul>
                {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <label className="text-white">
                First Name
            </label>
                <input
                type="text"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
                />
            <label className="text-white">
                Last Name
            </label>
                <input
                type="text"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
                />
            <label className="text-white">
                Email
            </label>
                <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
                />
            <label className="text-white">
                Username
            </label>
                <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
                />
            <label className="text-white">
                Password
            </label>
                <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
                />
            <label className="text-white">
                Confirm Password
            </label>
                <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
                />
            <button type="submit" className="h-10 my-6 border-white text-white border-[.1px] rounded-md w-[280px] bg-diagonal-gradient from-lightningblue to-darkpurple">Sign Up</button>
            <TurnstileWidget setIsVerified={setIsVerified} />
        </form>
        <div className="fixed top-0 left-0 right-0 bottom-0 w-screen z-[1] opacity-[85%] bg-black"></div>
    </div>
  );
}

export default SignupCTA;