import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../../store/session";
import LatestBooks from './LatestBooks';
import AlphabatizedBooks from './AlphabatizedBooks'
import BookMenu from './BookMenu';
import Profile from '../Profile';
import LoginSignup from '../../LoginSignup';
import Bookmarks from '../Bookmarks';

function Bookcard() {
    const dispatch = useDispatch();
    const sessionBooks = useSelector(state => state.session.books);
    const sessionAlphaBooks = useSelector(state => state.session.alphaBooks);
    const sessionAuthors = useSelector(state => state.session.authors);
    const sessionUser = useSelector(state => state.session.user);
    const [menuSelection, setMenuSelection] = useState("Latest Books");
    const [userId, setUserId] = useState(false);

    if (!userId && sessionUser) {
        setUserId(sessionUser.user_id);
    }

    useEffect(() => {
        const getBookData = async() => {
            dispatch(sessionActions.getBooks());
            dispatch(sessionActions.getAuthors());
            dispatch(sessionActions.getAlphaBooks());
            dispatch(sessionActions.getBookmarks(userId));
        };

        getBookData();
    },[dispatch]);


    return (
        <div className='overflow-y-auto h-[650px] max-w-[680px] self-center'>
            <BookMenu menuSelection={menuSelection} setMenuSelection={setMenuSelection} />

            {menuSelection === "Latest Books" &&
            <LatestBooks books={sessionBooks} authors={sessionAuthors} setMenuSelection={setMenuSelection} userId={userId} />
            }   
            {menuSelection === "Search" &&
            <AlphabatizedBooks alphaBooks={sessionAlphaBooks} authors={sessionAuthors} />
            }
            {menuSelection === "Bookmarks" &&
            <Bookmarks booksToShow={sessionBooks} authors={sessionAuthors} /> 
            }
            {menuSelection === "Profile" &&
            <Profile />
            }
            {menuSelection === "Login" &&
            <LoginSignup />
            }
        </div>

);
}

export default Bookcard;