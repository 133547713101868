import { React } from 'react';
import Turnstile, { useTurnstile } from "react-turnstile";
import { csrfFetch } from '../../store/csrf.js';

function TurnstileWidget({ setIsVerified }) {
    const turnstile = useTurnstile();

  return (
    <Turnstile
      sitekey="0x4AAAAAAAW1eJdVBWMxqeSG"
      onVerify={(token) => {
        csrfFetch("/api/session/verify-login", {
          method: "POST",
          body: JSON.stringify({ token }),
        }).then((response) => {
          if (response.ok) {
            setIsVerified(true);
        } else {
            turnstile.reset();
        }
        });
      }}
    />
  );
}

export default TurnstileWidget;