import React, { useState, useEffect } from 'react';
import * as sessionActions from '../../../store/session';
import { useDispatch, useSelector } from 'react-redux';
import TurnstileWidget from '../../TurnstileWidget';

function LoginModal({ setShowLogin }) {

    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);
    const [credential, setCredential] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [isVerified, setIsVerified] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      if (!isVerified) {
        setErrors(["Please complete the security challenge"]);
        return;
      }
      setErrors([]);
      return dispatch(sessionActions.login({ credential, password }))
              .then(res => res.json)
              .then(data => {
                if (data) {
                  window.location.reload();
                }
              })
        .catch(async (res) => {
          const data = await res.json();
          if (data && data.errors) {
            setErrors(data.errors);
          }
        });
    }
  
    return (
      <div className="mt-2 flex flex-col justify-center items-center font-scrivano">
        <h2 className="text-white text-3xl mb-8">Login</h2>
        <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center text-white">
            <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <label>
            Username or Email
            </label>
            <input
                type="text"
                value={credential}
                onChange={(e) => setCredential(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
            />
            <label>
            Password
            </label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-[280px] my-2 text-black"
            />
            <button type="submit" className="h-10 my-6 border-white border-[.1px] rounded-md w-[280px] bg-diagonal-gradient from-lightningblue to-darkpurple">Log In</button>
            <TurnstileWidget setIsVerified={setIsVerified} />
        </form>
        <div className="opacity-25 border-[.5px] border-white w-[96%] mt-6 mb-6"></div>
        <p className="text-white">OR</p>
        <p onClick={() => setShowLogin(false)} className="mt-6 text-xl text-lightningblue">Sign Up</p>
      </div>
    );
}

export default LoginModal;