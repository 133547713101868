import { React } from 'react';
import { ReactComponent as RigthArrow } from '../../../Assets/Images/right-arrow.svg';
import '../../../Assets/Animations/animation.css';

function PageThree() {

    const navToLexicon = () => {
        window.location.href = '/lexicon'
    }
    
    return (
        <div className="flex flex-col items-center justify-center mb-14">
            <h1 className="px-6 text-center font-rift-soft text-[30px] text-lightningblue tracking-[14px] animate-grow">
                Unlock the Secrets
            </h1>
            <div className="border-[.5px] border-white w-[80%] my-4"></div>
            <p className="leading-6 px-8 owners-text text-center tracking-[.5px] text-white text-xs animate-grow">
            Despite its immeasurable worth, Captain H.H. Hedgehunter hath refused countless offers of riches beyond measure, opting rather to share it with the common folk at no cost. Behold for thyself:          
            </p>    
            <button onClick={navToLexicon} className="mt-10 bg-lightningblue hover:bg-purple-700 text-purple-900 font-semibold py-2 px-4 rounded inline-flex items-center border-1">
                <span>Let's Go!</span>
            </button>
        </div>
    )
}

export default PageThree;