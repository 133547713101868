import React, { useState, useEffect } from "react";
import { csrfFetch } from '../../../store/csrf.js';

const CreateBook = ({ title, author_name, summary, setShowCreateBookForm, setShowCreateBookFormSuccessMessage }) => {
    const [authorName, setAuthorName] = useState(author_name);
    const [inputTitle, setInputTitle] = useState(title);
    const [inputSummary, setInputSummary] = useState(summary)
    const [is_premium, setIsPremium] = useState(false);
    const [ref_link, setRefLink] = useState("");
    const [imageUrl, setImageUrl] = useState("");


    const submitBook = async(e) => {
        e.preventDefault();
        const response = await csrfFetch("/api/captain/create-book", {
            method: "POST",
            body: JSON.stringify({
                author_name: authorName, 
                title: inputTitle, 
                summary: inputSummary,
                is_premium: is_premium, 
                ref_link: ref_link, 
                image_url: imageUrl
            })
          });
          const data = await response.json();
    
          if(data) {
            setShowCreateBookForm(false);
            setShowCreateBookFormSuccessMessage(true)
          } else {
            console.log("Error")
          }
    }

    const hideBookForm = () => {
        setShowCreateBookForm(false);
    }

    const closeBookFormSuccessMessage = () => {
      setShowCreateBookFormSuccessMessage(false)
    }
    
    return(
        <div className="flex flex-col items-center justify-center mt-10">
            <form className="flex flex-col items-center justify-center mt-10" onSubmit={submitBook}>
                <label>
                  Author Name
                </label>
                <input 
                    className="border-2 border-black"
                    type="text"
                    value={authorName}
                    onChange={(e) => setAuthorName(e.target.value)}
                    required
                  />
                <label>
                  Title
                </label>
                <input 
                    className="border-2 border-black"
                    type="text"
                    value={inputTitle}
                    onChange={(e) => setInputTitle(e.target.value)}
                    required
                  />
                <label>
                  Summary
                </label>
                <input 
                    className="border-2 border-black"
                    type="text"
                    value={inputSummary}
                    onChange={(e) => setInputSummary(e.target.value)}
                    required
                  />
                <label>
                  Is Premium?
                </label>
                <input 
                    className="border-2 border-black"
                    type="text"
                    value={is_premium}
                    onChange={(e) => setIsPremium(e.target.value)}
                    required
                  />
                <label>
                  Ref Link
                </label>
                <input
                    className="border-2 border-black"
                    type="text"
                    value={ref_link}
                    onChange={(e) => setRefLink(e.target.value)}
                    required
                  />
                <label>
                  Image URL
                </label>
                <input
                    className="border-2 border-black"
                    type="text"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    required
                  />
                <button type="submit">Submit</button>
                <button onClick={hideBookForm} >Close</button>
            </form>
    
        </div>
    )
}

export default CreateBook;