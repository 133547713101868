import { React, useEffect, useState } from 'react';

function CookiesBanner({ showBanner, setShowBanner }) {
    
    const handleClose = () => {
        setShowBanner(false);
    }

    useEffect(() => {
        if (!showBanner) {
            return handleClose();
        }
    },[showBanner])


    const setCookie = () => {
        // set the cookie with a expiration date of one week from now
        let date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
        document.cookie = "cookieUse=agreed; expires=" + date.toGMTString();
        setShowBanner(false);
        handleClose();
    }

    return (
        <div className="w-full fixed bottom-0 h-[40px] bg-lightningblue z-10 overflow-auto">
            <div className="flex px-8">
                <p className="text-xs px-4 self-center">This site uses cookies</p>
                <div className="flex justify-center items-center py-2">
                    <p className="px-2 mr-2 hover-mouse border-black border-2 rounded-md hover:cursor-pointer" onClick={() => setCookie()}>Accept</p>
                    <p className="hover:cursor-pointer" onClick={() => handleClose()}>Reject</p>
                </div>
            </div>
        </div>
    )
}

export default CookiesBanner;