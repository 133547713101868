import { React, useEffect, useState } from 'react';
import { ReactComponent as RigthArrow } from '../Assets/Images/right-arrow.svg';
import  LandingPage  from '../GetStarted/LandingPage';

import '../Assets/Animations/animation.css';
function GetStartedPage() {
    const [displayLanding, setDisplayLanding] = useState(false);

    const nextPage = () => {
        setDisplayLanding(true);
      };

    return (
        <div className="w-screen h-screen bg-diagonal-gradient from-darkpurple to-bluegreen flex flex-col justify-center">

            {!displayLanding && <div className="flex flex-col items-center justify-center">
                <h1 className="px-6 mb-10 text-center font-rift-soft text-[50px] text-lightningblue tracking-[14px] animate-grow">
                    The Investment Lexicon
                </h1>
                <p className="font-rift-soft tracking-[3px] text-white text-l mb-10 animate-grow">Unlock the Ancient Investment Wisdom</p>
                <RigthArrow onClick={nextPage} className="h-[42px] animate-grow animate-pulse" />
            </div>
            }

            {displayLanding && <LandingPage setDisplayLanding={setDisplayLanding} />
            }
        </div>
    )
}

export default GetStartedPage;