import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { csrfFetch } from '../../store/csrf.js';
import CreateBookdraft from "./CreateBookdraft/index.js";
import CreateBook from "./CreateBook/index.js";
import Navigation from "../Navigation/index.js";

const BookManager = () => {
    const [showBookdraftForm, setShowBookdraftForm] = useState(false);
    const [showCreateBookForm, setShowCreateBookForm] = useState(false);
    const [bookdrafts, setBookdrafts] = useState([]);
    const [bookDraftTable, setBookdraftTable] = useState("");
    const [bookSwal, setBookSwal] = useState("");
    const sessionUser = useSelector(state => state.session.user);
    const [showCreateBookFormSuccessMessage, setShowCreateBookFormSuccessMessage] = useState(false);

    useEffect(() => {
        const isCaptain = async() => {
            try {
                const user_id = sessionUser.user_id;
                const response = await csrfFetch("/api/captain/is-captain", {
                    method: "POST",
                    body: JSON.stringify({
                    user_id,
                    }),
                });
                const data = await response.json();
                return data;
            } catch {
                return
            }
        }
        const getBookdrafts = async() => {
            if (await isCaptain()) {
                console.log(isCaptain())
            const response = await csrfFetch("/api/captain/get-bookdrafts", {
                method: "GET",
              });
              const data = await response.json();
              setBookdrafts(data.bookdrafts)
            } else {
                window.location = "/"
            }
        }

        getBookdrafts();
    }, [sessionUser])

    const deleteBookdraft = async(bookdraftId) => {
        try {
            const bookdraft_id = bookdraftId;
            const response = await csrfFetch("/api/captain/delete-bookdraft", {
                method: "POST",
                body: JSON.stringify({
                    bookdraft_id,
                }),
            });
            const data = await response.json();
            return data;
        } catch {
            return
        }
    }

    const toggleCreateBookForm = (title, author_name, summary) => {
        setShowCreateBookForm(true);
        setBookSwal(
         <CreateBook title={title} author_name={author_name} summary={summary} setShowCreateBookForm={setShowCreateBookForm} setShowCreateBookFormSuccessMessage={setShowCreateBookFormSuccessMessage} />
        )
    }

    useEffect(() => {
        setBookdraftTable(
            <table className="max-w-[900px] border-2 border-lightningblue text-white">
                <thead>
                    <tr className="border-2 border-lightningblue">
                        <th>Title</th>
                        <th>Author</th>
                        <th>Summary</th>
                        <th>Created Date</th>
                        <th>Create Book From Draft</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {bookdrafts?.map((book, index) => (
                        <tr key={index} className="border-2 border-lightningblue" >
                            <td className="p-[10px]">{book.title}</td>
                            <td>{book.author_name}</td>
                            <td>{book.summary.slice(0,60)}</td>
                            <td>{book.createdAt}</td>
                            <td onClick={() => toggleCreateBookForm(book.title, book.author_name, book.summary)}><button className="h-[30px] px-2 rounded-md bg-lightningblue">Create Book</button></td>
                            <td onClick={() => deleteBookdraft(book.bookdraft_id)}><button className="h-[30px] px-2 rounded-md bg-bluegreen">Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }, [bookdrafts])

    const showBookdraft = () => {
        setShowBookdraftForm(true);
    }

    return(
        <div className="flex flex-col h-[1200px] items-center w-screen bg-diagonal-gradient from-darkpurple to-bluegreen overflow-hidden overflow-x-auto">
            <Navigation />
            <h1 className="text-xl text-white font-scrivano mt-10">Book Manager</h1>

            {!showBookdraftForm && !showCreateBookForm && bookdrafts && 
            <div className="flex flex-col justify-center items-center mt-12">
                {bookDraftTable}
                <button onClick={showBookdraft} className="mt-10 bg-lightningblue hover:bg-purple-700 text-purple-900 font-semibold py-2 px-4 rounded inline-flex items-center border-1">
                    <span>Create Bookdraft</span>
                </button>    
            </div>
            }

            {showBookdraftForm && 
            <CreateBookdraft user={sessionUser} setShowBookdraftForm={setShowBookdraftForm} />
            }

            {showCreateBookForm &&
            <div>
            {bookSwal}
            </div>
            }

            {showCreateBookFormSuccessMessage && 
            <div className="text-white text-xl mt-4">
              <p>Book Successfully created, bookdraft can be deleted.</p>
            </div>
            }

        </div>
    )
}

export default BookManager;