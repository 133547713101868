import { React } from 'react';
import { ReactComponent as RigthArrow } from '../../../Assets/Images/right-arrow.svg';
import '../../../Assets/Animations/animation.css';

function PageOne() {

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="px-6 text-center font-rift-soft text-[30px] text-lightningblue tracking-[14px] animate-grow">
                The Secrets
            </h1>
            <div className="border-[.5px] border-white w-[80%] my-4"></div>
            <p className="leading-6 px-8 owners-text text-center tracking-[.5px] text-white text-xs mb-16 animate-grow">
                The Investanomicon is a mysterious lexicon that holds the knowledge of unlimited investment wisdom.
            </p>

            <h1 className="px-6 text-center font-rift-soft text-[30px] text-lightningblue tracking-[14px] animate-grow">
                What Does It Do?
            </h1>
            <div className="border-[.5px] border-white w-[80%] my-4"></div>
            <p className="leading-6 px-8 owners-text text-center tracking-[.5px] text-white text-xs mb-16 animate-grow">
              This powerful library encompasses the summarized texts of every investment publication known to mankind, holding the key to unlocking financial freedom. Every day, pages are meticulously transcribed from the Investanomicon and uploaded to the site, enriching it further.           
            </p>

        </div>
    )
}

export default PageOne;