import { React, useState } from 'react';
import Bookcard from './Cards/Bookcard';
import Navigation from '../Navigation';
import CookiesBanner from '../CookiesBanner';

function Lexicon() {
    const [showBanner, setShowBanner] = useState(true);
    
    return (
        <div className="flex flex-col justify-center items-center w-screen bg-diagonal-gradient from-darkpurple to-bluegreen overflow-hidden pb-4">
            <Navigation />

            <div className="flex flex-col justify-center">
                <Bookcard />
            </div>
            {showBanner && <CookiesBanner showBanner={showBanner} setShowBanner={setShowBanner} />}
        </div>
    )
}

export default Lexicon;