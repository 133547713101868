import { React, useState } from 'react';
import { ReactComponent as LeftArrow } from './../../Assets/Images/left-arrow.svg';
import { ReactComponent as RigthArrow } from './../../Assets/Images/right-arrow.svg';
import './../../Assets/Animations/animation.css';
import PageOne from './Pages/PageOne.js';
import PageTwo from './Pages/PageTwo.js';
import PageThree from './Pages/PageThree.js';

function LandingPage({ setDisplayLanding }) {
    const [pageToDisplay, setPageToDisplay] = useState(1);

    const back = () => {
        if (pageToDisplay == 1) {
        setDisplayLanding(false); 
        } else {
            setPageToDisplay(pageToDisplay - 1)
        }
      };

      const next = () => {
        setPageToDisplay(pageToDisplay + 1)
      };

    return (
        <div className="flex flex-col items-center justify-center mt-10">

            {pageToDisplay == 1 &&
                <PageOne />
            }
            {pageToDisplay == 2 &&
                <PageTwo />
            }
            {pageToDisplay == 3 &&
                <PageThree />
            }
            <div className="flex space-x-6">
                <LeftArrow onClick={back} className="h-[42px] animate-grow animate-pulse" style={{ transform: 'rotate(90deg)' }} />
                {pageToDisplay !== 3 && <RigthArrow onClick={next} className="h-[42px] animate-grow animate-pulse" style={{ transform: 'rotate(90deg)' }} />
                }
            </div>
        </div>
    )
}

export default LandingPage;