import { React } from 'react';
import { ReactComponent as RigthArrow } from '../../../Assets/Images/right-arrow.svg';
import '../../../Assets/Animations/animation.css';

function PageTwo() {

    return (
        <div className="flex flex-col items-center justify-center">

            <h1 className="px-6 text-center font-rift-soft text-[30px] text-lightningblue tracking-[14px] animate-grow">
                Origin Story
            </h1>
            <div className="border-[.5px] border-white w-[80%] my-4"></div>
            <p className="overflow-y-auto h-[480px] max-h-screen leading-6 px-8 owners-text text-start tracking-[.5px] text-white text-xs mb-12 animate-grow">
                Upon a time when the seas were unruled and free, there lived a pirate captain of renown, H.H. Hedgehunter, who did valiantly defend a helpless village in the Carribiean from a full battalion of marauding enemies. With his ship anchored yonder, Hedgehunter embarked on a perilous voyage across the treacherous seas to procure healing salves and potions for the wounded villagers. His journey took an unexpected turn when his vessel was besieged by a rival pirate crew, forcing it to strand on an unknown island's shores. Undaunted, Hedgehunter drew his cutlass and began his trek through the dense jungle.
                <br /><br />
                Resolved to complete his quest, Hedgehunter faced a continuous barrage from the enemy pirates. Against all odds, he vanquished each adversary with deadly precision. Days into his expedition, he faced a greater peril—an ambush by a vast crew of pirates. Outnumbered, the cunning captain did what he had never considered before: he retreated. This strategic withdrawal led him to a concealed cave entrance, which he entered in hopes of eluding his chasers.
                <br /><br />
                Within the cave, Hedgehunter chanced upon an intricate network of caverns. 'Twas here, in the earth's deep bosom, that he discovered the Investanomicon. This ancient chest was filled with untold riches and knowledge of the seven seas, wisdom that any sailor could instantly understand upon setting eyes upon its contents. Overwhelmed by the significance of his find, Hedgehunter was awestruck by the power the Investanomicon held.
                <br /><br />
                After navigating his way out of the cave and returning to the village, Captain Hedgehunter was consumed by a new quest: to decipher the secrets of the Investanomicon and share its unparalleled riches with the world. The voyage ahead was fraught with danger, but the promise of securing fortunes for many steeled his resolve. The Investanomicon was not merely a chest; it was a lighthouse of hope, and Captain Hedgehunter, its bearer.
            </p>
        </div>
    )
}

export default PageTwo;