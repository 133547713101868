import React, { useState } from 'react';
import * as sessionActions from '../../../store/session';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TurnstileWidget from '../../TurnstileWidget';

function SignupModal({ setShowLogin }) {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (!isVerified) {
        setErrors(["Please complete the security challenge"]);
        return;
      }
      if (password === confirmPassword) {
        setErrors([]);
        return dispatch(sessionActions.signup({ first_name, last_name, email, username, password }))
          .then(res => res.json)
          .then(data => {
            if (data) {
              window.location.reload();
            }
          })
          .catch(async (res) => {
            const data = await res.json();
            if (data && data.errors) setErrors(data.errors);
          });
      }
      return setErrors(['Confirm Password field must be the same as the Password field']);
    };
  
    return (
      <div className="mt-2 flex font-scrivano flex-col justify-center items-center w-full">
        <h2 className="text-white text-3xl mb-8">Sign Up</h2>            
        <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center w-full text-white">
                <ul>
                {errors.map((error, idx) => <li key={idx}>{error}</li>)}
                </ul>
                <label className="self-start pl-14">
                First Name
                </label>
                <input
                    type="text"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="w-[280px] my-2 text-black"
                />
                <label className="self-start pl-14">
                Last Name
                </label>
                <input
                    type="text"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="w-[280px] my-2 text-black"
                />
                <label className="self-start pl-14">
                Email
                </label>
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-[280px] my-2 text-black"
                />
                <label className="self-start pl-14">
                Username
                </label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="w-[280px] my-2 text-black"
                />
                <label className="self-start pl-14">
                Password
                </label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-[280px] my-2 text-black"
                />
                <label className="self-start pl-14">
                Confirm Password
                </label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="w-[280px] my-2 text-black"
                />
                <button type="submit" className="h-10 my-6 border-white border-[.1px] rounded-md w-[280px] bg-diagonal-gradient from-lightningblue to-darkpurple">Sign Up</button>
                <TurnstileWidget setIsVerified={setIsVerified} />
            </form>
            <div className="opacity-25 border-[.5px] border-white w-[96%] mt-6 mb-6"></div>
        <p className="text-white">OR</p>
        <p onClick={() => setShowLogin(true)} className="mt-6 text-xl text-lightningblue">Login</p>
      </div>
    );
}

export default SignupModal;